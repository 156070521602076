export default {
  data: () => ({
    category: {
      id: 0,
      name: "",
      company_id:'',
      url_slug: "",
      meta_title: "",
      meta_description: "",
      og_tc_title: "",
      og_tc_description: "",
      is_same_og_tc: false,
      alt_text: "",
      og_tc_image:"",
      priority:"",
      isCheked: false,
    },
    errorMessage: "",
    showPreview: false,
    imageName: "",
    isHovering: false,
    imagePreviewSrc:'',
    webUrl: process.env.VUE_APP_BLOG_URL,
    loading: false,
    companyList:[],
    backUrl: "/category",
  }),

  watch: {
    'category.is_same_og_tc': function(value) {
      if (value) {
        this.category.og_tc_title = this.category.meta_title;
        this.category.og_tc_description = this.category.meta_description;
      }else {
        this.category.og_tc_title = this.category.og_tc_title;
        this.category.og_tc_description = this.category.og_tc_description;
      }
    },

  },

  computed: {
    isSameOgTcValue() {
      return this.category.is_same_og_tc ? 1 : 0;
    },
    getRules() {
      return this.category.og_tc_image ? '' : 'required';
    },
    getDataTitle() {
      return this.imageName ? this.imageName : "Drag your image here";
    },
  },

  methods: {
    resetForm() {
      this.$refs.observer.reset();
      this.category = {
        id: 0,
        name: "",
      };
    },
    updateIsSameOgTcValue(event) {
      this.category.is_same_og_tc = event.target.checked;
    },
    openImageInNewTab() {
      let _vm = this;
      if(_vm.category.id > 0 ){
       window.open(this.category.full_image_url, "_blank");
      }
      else{
        return false
      }
    },
    validateDepartment() {
      // Check if the category ID is greater than 0
      // If true, call the update() method; otherwise, call the add() method
      this.category.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
    },
    update() {
      let _vm = this;
      let fd = new FormData();

      _vm.category.is_same_og_tc = _vm.category.is_same_og_tc ? 1 : 0;

      _vm.category.url_slug = _vm.category.url_slug.toLowerCase();
      if (_vm.category.og_tc_image) {
        fd.append("image", _vm.category.og_tc_image);
      }
      Object.keys(_vm.category).forEach((key) => {
        fd.append(key, _vm.category[key]);
      });
      fd.append("_method", "PATCH");
      this.axios
        .post("/blog/categories/" + this.category.id,fd)
        .then(function (response) {
          // Update the category data with the response data
          _vm.category = response.data.data;
          // Redirect to the specified back URL
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    add() {
      let _vm = this;
      let fd = new FormData();
      _vm.category.is_same_og_tc = _vm.category.is_same_og_tc ? 1 : 0;

      _vm.category.url_slug = _vm.category.url_slug.toLowerCase();

      if (_vm.category.og_tc_image) {
        fd.append("image", _vm.category.og_tc_image);
      }
      Object.keys(_vm.category).forEach(key => {
        fd.append(key, _vm.category[key]);
      });
      this.axios
        .post("/blog/categories", fd, _vm.category)
        .then(function () {
          // Redirect to the specified back URL
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/blog/categories/" + this.$route.params.id)
        .then(function (response) {
          _vm.category = response.data.data;

          if (_vm.category.full_image_url && _vm.category.full_image_url.length > 0) {
            _vm.imagePreviewSrc = _vm.category.full_image_url;
            _vm.showPreview = true;
          } else {
            _vm.category.og_tc_image = null;
            _vm.showPreview = false;
          }
        })
        .catch(function () {});
    },
    selectImage(event) {
      let _vm = this;
      var input = event.target;
      if (input.files && input.files[0]) {
        var file = input.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var width = this.width;
            var height = this.height;

            if (width === 1200 && height === 630) {
              _vm.category.og_tc_image = file;

              _vm.imageName = file.name;
              _vm.errorMessage = "";
              _vm.showPreview = true;
              _vm.imagePreviewSrc = e.target.result;
            } else {
              _vm.category.og_tc_image = null;
              _vm.imageName = "";
              _vm.errorMessage = "Please select an image with a size of 1200x630 pixels.";
              _vm.showPreview = false;
              _vm.imagePreviewSrc = "";
            }
          };
        };
        reader.readAsDataURL(file);
      }
    },
    getErrorMessage(validationError) {
      if (validationError === "required") {
        return "Image is required.";
      } else {
        return "Please select an image with a size of 1200x630 pixels.";
      }
    },
    resetImage() {
      this.category.og_tc_image = null;
      this.category.full_image_url = null; // Reset the image data
      this.imageName = ""; // Reset the image name
    },
    showResetButton() {
      this.isHovering = true;
    },
    hideResetButton() {
      this.isHovering = false;
    },
    updateUrl(event){
      if (event.code === 'Space') {
        const inputText = event.target.value.replace(/\s{1,}/g, '-').toLowerCase();
        if (this.category.url_slug.length > 1 && this.category.url_slug[this.category.url_slug.length - 2] === '-') {
            this.category.url_slug = inputText;
        } else {
            this.category.url_slug = inputText;
        }
      }
    },
    getCompany() {
      let _vm = this;
      this.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
          _vm.category.company_id = _vm.category.company_id ? _vm.category.company_id : _vm.companyList[0].id;
        })
        .catch(function () { });
    },
  },
  mounted() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== "") {
      this.getDetail();
    }
    this.getCompany();  
  },
};
